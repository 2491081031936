import React from "react";

import { FormattedMessage, useIntl } from "react-intl";
import { PrivateTemplate } from "../../Templates";
import { Helmet } from "react-helmet";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";

const SuccessPrintingPage = () => {

    const intl = useIntl();

    return (
        <PrivateTemplate>
            <Helmet>
                <title>Zerocopy - {intl.formatMessage({id: "xxx", defaultMessage: "Success printing order"})}</title>
            </Helmet>
            <Container fluid className="text-center">
                <Row className="mb-3">
                    <Col className="pl-0">
                        <FormattedMessage id="xxx" defaultMessage="Your order was made." />
                    </Col>
                </Row>
                <Row>
                    <Col className="pl-0">
                        <Link to="/" className="btn btn-outline-secondary text-decoration-none mt-2">
                            <FormattedMessage id="xxx" defaultMessage="Back to your library" />
                        </Link>
                    </Col>
                </Row>
            </Container>
        </PrivateTemplate>
    );

}

export default SuccessPrintingPage;