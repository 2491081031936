import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, matchPath } from 'react-router-dom';
import { Badge, NavbarBrand, NavbarText } from 'reactstrap';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { AccountIcon, LocationMapIcon, PackageIcon, AiIcon } from '../Icons';
import ViewCarouselRoundedIcon from '@material-ui/icons/ViewCarouselRounded';

import './Toolbar.css';
import { DocumentUploadButton } from '../Documents';
import { useCompetition } from '../../Competition/CompetitionContext';
import { SwipeableDrawer } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { SmallAd } from '../Ads/SmallAds/SmallAd';
import AdSenseWrapper from '../../Components/Ads/AdSenseWrapper';
import { drawerWidth } from '../../Templates/PrivateTemplate';
import './MuiToolbar.css'
import { QuestionAnswerRounded } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: "0px 0px 0px 0px rgba(0, 0, 0, 0)",
    backgroundColor: "#8bc53f",
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    boxShadow: "0px 0px 0px 0px rgba(0, 0, 0, 0)",
    [theme.breakpoints.only('md')]: {
      width: `calc(108% - ${drawerWidth}px)`,
    },
    [theme.breakpoints.only('sm')]: {
      width: `calc(107% - ${drawerWidth}px)`,
    },
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: "#8bc53f",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    flexShrink: 0,
    width: drawerWidth,
  },
  nested: {
    paddingLeft: theme.spacing(4.5),
  },
  list: {
    [theme.breakpoints.up('lg')]: {
      paddingTop: "4rem",
    },
    color: "var(--secondary-color)",
  },
  icon: {
    color: "var(--secondary-color) !important"
  }
}));

const MuiToolbar = ({location}) => {
  const theme = useTheme();
  const classes = useStyles();
  const lg = useMediaQuery(theme.breakpoints.up("lg"))
  const { competition } = useCompetition();

  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  const isSelected = (path) => {
    return matchPath(location.pathname, {
    "path": path,
    "exact": true
    }
    ) ? true : false
  };

  const isHomePage = matchPath(location.pathname, {
    "path": "/documents",
    "exact": true,
    "strict": true
  });

  const isAiForm = matchPath(location.pathname, {
    "path": "/ai/(quiz-generator|flashcards-generator)",
    "exact": false,
    "strict": false
  });

  const buttons = 
      <div className='w-100 d-flex justify-content-end align-items-center'>
        {!isHomePage && !isAiForm && <DocumentUploadButton /> }
      </div>

  const listItem = (key, icon, destination, testAttr) => {
    return (
      <ListItem selected={isSelected(destination)} button key={key} component={Link} to={destination}  data-test={testAttr}>
        <ListItemIcon className={classes.icon} >{icon}</ListItemIcon>
        <ListItemText primary={<FormattedMessage id={`${key}`}/>} />
      </ListItem>
    )
  }


  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={classes.appBar}
      >
        <Toolbar>
          {!lg ?
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
          : null }
          {lg || !open ? 
          <NavbarBrand tag={Link} to="/" className="ml-lg-3 new-users-logo">
            <img src={require("../../images/logo-white-h.png")} alt="Zerocopy" width="178" height="30" />
          </NavbarBrand>
          : null }
          {buttons}        
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        className={classes.drawer}
        variant={lg ? "permanent" : "temporary"}
        open={open}
        onClose={handleDrawerClose}
        anchor="left"
        elevation={0}
        onOpen={() => {}}
      >
        <List component="nav" className={`main-menu ${classes.list}`}>
          {listItem("toolbar.logged.home", <MenuBookIcon/>, "/documents", "toolbar-menu-documents")}
          <ListItem button key={"ai.tutor"} selected={isSelected("/ai")} component={Link} to={"/ai"} data-test={"toolbar-menu-ai" }>
            <ListItemIcon  className={classes.icon}><AiIcon className={"svg-secondary-color"}/></ListItemIcon>
            <ListItemText primary={
              <>
                <FormattedMessage id="ai.tutor" values={{ icon: null }} />
                <span> &nbsp;</span>
                <Chip label="BETA" size="small" color="secondary" style={{marginTop: "-4px"}}/>
              </>
            } />
          </ListItem>
            <List component="div" disablePadding>
              <ListItem button component={Link} selected={isSelected("/ai/flashcards")} to={"/ai/flashcards"} className={classes.nested}>
                <ListItemIcon className={classes.icon}>
                  <ViewCarouselRoundedIcon />
                </ListItemIcon>
                <ListItemText primary="Flashcards" />
              </ListItem>
              <ListItem button component={Link} selected={isSelected("/ai/quiz")} to={"/ai/quiz"} className={classes.nested}>
                <ListItemIcon className={classes.icon}>
                  <QuestionAnswerRounded />
                </ListItemIcon>
                <ListItemText primary="Quiz" />
              </ListItem>
            </List>
          {listItem("deliveries.list.title", <PackageIcon/>,"/user/deliveries", "toolbar-menu-deliveries")}
          {listItem("copyshop.menu" , <LocationMapIcon/>,"/copyshop", "toolbar-menu-copyshop")}
          {listItem("account.title" , <AccountIcon className={"white"}/>,"/user/account", "toolbar-menu-account")}
          {competition ? listItem("competitions.menu" ,<i className="fas fa-gift fa-fw mr-1" ></i>,"/compeition", "toolbar-menu-competition") : null}
        </List>
        
        { lg ? <AdSenseWrapper adSlot="8102201663" className="small-adsense" smallAd={true}>
          <SmallAd className={'justify-content-center p-2'}/>
        </AdSenseWrapper> : null }
      </SwipeableDrawer>
    </div>
  );


}

export default MuiToolbar;
