import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Alert, Col, Container, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import ConfigurationService, { DELIVERY_ENABLED } from "../../Services/ConfigurationService";
import { CS_TYPE, DELIVERY_TYPE } from "../Services/PrintingService";
import { useHistory } from "react-router-dom";
import { UserService } from "../../Services";

const PrintingTypeSelector = ({toggle}) => {

  const documents = useSelector((state) => state.documents.list.filter(doc => state.documents.selected.documents.includes(doc.id)));
  const [totalPagesPlusAds, setTotalPagesPlusAds] = useState(0);
  const [maxPages, setMaxPages] = useState(0);
  const [disabledDeliveryMaxPages, setDisabledDeliveryMaxPages] = useState(false);
  const [disabledDelivery, setDisabledDelivery] = useState(false);

  const history = useHistory();

  useEffect(() => {
    console.log("SSSSS");
  }, []);

  useEffect(() => {

    if (documents?.length > 0) {
      setTotalPagesPlusAds(documents.reduce((acc, currentValue) => acc + currentValue.numberOfPages + currentValue.numberOfFullPageAds, 0));

      ConfigurationService.getConfigurations([DELIVERY_ENABLED])
      .then(response => {
        setDisabledDelivery(response.data.find(config => config.key === DELIVERY_ENABLED).value !== "true");
      }).catch(error => {
        logger.error("Error while try to obtain configuration values", error);
      });

      UserService.getConfiguration().then(response => {
        setMaxPages(response.data.maxPagesPerOrder)
      }).catch(error => {
        logger.error("Error fetching user configuration", error)
        setMaxPages(0);
      });
      
    }

  }, [documents]);

  useEffect(() => {

    if (totalPagesPlusAds <= 0 || maxPages <= 0) {
      return;
    }

    if (totalPagesPlusAds < maxPages) {
      setDisabledDeliveryMaxPages(false);
    } else {
      setDisabledDeliveryMaxPages(true);
    }

  }, [totalPagesPlusAds, maxPages]);

  const onCopyShopClick = (e) => {
    history.push(`/printing?type=${CS_TYPE}`);
  }

  const onDeliveryClick = (e) => {
    history.push(`/printing?type=${DELIVERY_TYPE}`);
  }

  return (
    <Modal isOpen={true} toggle={toggle} size="lg" centered>
      <ModalHeader toggle={toggle}>
        <FormattedMessage id="documents.btn.order" />
      </ModalHeader>
      <ModalBody>

        {disabledDelivery && <DisabledDelivery /> }
        {disabledDeliveryMaxPages && <DisabledDeliveryMessage maxPages={maxPages}/> }

        <Container fluid className="p-0">
          <Row className="mb-4">
            <Col>
              <FormattedMessage id="documents.printForFree.choose" />
            </Col>
          </Row>
          <Row className="text-white">
            <Col>
              <TypeButton headerKey="documents.printForFree.cs" onClick={onCopyShopClick} dataTest="copyshop-button">
                  <FormattedMessage id="documents.printForFree.cs.options" values={{
                    breakLine: (<div className='mt-1' />),
                    ul: (...chunks) => (<ul className='mb-0' style={{paddingInlineStart: "1rem"}}>{chunks}</ul>),
                    li: (text) => (<li>{text}</li>)
                  }} />
              </TypeButton>
            </Col>
            <Col>
              <TypeButton headerKey="documents.printForFree.delivery" disabled={disabledDelivery || disabledDeliveryMaxPages} onClick={onDeliveryClick} dataTest="delivery-button">
                  <FormattedMessage id="documents.printForFree.delivery.options" values={{
                    breakLine: (<div className='mt-1' />),
                    ul: (...chunks) => (<ul className='mb-0' style={{paddingInlineStart: "1rem"}}>{chunks}</ul>),
                    li: (text) => (<li>{text}</li>)
                  }} />
              </TypeButton>
            </Col>
          </Row>
        </Container>
                    
      </ModalBody>
    </Modal>


  );
}

export default PrintingTypeSelector;

const TypeButton = ({children, headerKey, disabled = false, onClick, dataTest}) => {

  return (
    <div className={`h-100 w-100 btn btn-primary p-3 ${disabled ? " disabled c-not-allowed": "c-pointer"}`} onClick={onClick} role='button' data-test={dataTest}>
      <div className='mb-3 text-center font-weight-bold fs-5'>
        <FormattedMessage id={headerKey} />
      </div>
      <div className="text-left small">
        {children}
      </div>
    </div>
  );

}


const LocalGlobalMessage = ({color = "danger", children}) => {
  return (
    <Row>
      <Col>
        <Alert color={color}>
          {children}
        </Alert>
      </Col>
    </Row>
  );
}

const DisabledMessage = () => {
  return (
    <LocalGlobalMessage>
      <FormattedMessage id="xxx" defaultMessage="At this moment we cannot allow the printing request. Please, try again or contact the support team." />
    </LocalGlobalMessage>
  );
}

const DisabledDeliveryMessage = ({maxPages}) => {
  return (
    <LocalGlobalMessage>
      <FormattedMessage id="deliveries.request.message.error.maxPages" values={{max: maxPages}} />
    </LocalGlobalMessage>
  );
}

const DisabledDelivery= () => {
  return (
    <LocalGlobalMessage>
      <FormattedMessage id="deliveries.request.message.disabled" />
    </LocalGlobalMessage>
  );
}