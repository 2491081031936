import api from '../Api';
import { getToken } from '../shared/utils';

export const PRICE_PER_PRINTED_PAGE = "price_per_printed_page";
export const DAILY_FREE_PAGES = "daily_free_pages";
export const CS_TRANSACTION_FEE = "cs_transaction_fee";
export const DELIVERY_ENABLED = "system_enable_home_delivery";
export const PARTNER_EMAIL_ENABLED = "system_enable_partner_emails";

class ConfigurationService {

  /**
   * Obtain the value of specific configuration property
   * @param {String} configurationKey Key of the configuration property.
   */
  static async getConfiguration(configurationKey) {
    return api.get(`/v1/metadata/${configurationKey}`, {headers: {'X-AUTH-TOKEN': getToken()}});
  }

    /**
   * Obtain the value of several configuration properties
   * @param {Array} configurationKeys Array of string with the keys of the configuration properties.
   */
    static async getConfigurations(configurationKeys) {
      return api.post(`/v1/metadata`, configurationKeys, {headers: {'X-AUTH-TOKEN': getToken()}});
    }

}

export default ConfigurationService;