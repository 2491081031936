import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import PrintingService from "../Services/PrintingService";
import CustomSpinner from "../../Components/Commons/Spinner";
import { FormattedMessage, useIntl } from "react-intl";
import { PrivateTemplate } from "../../Templates";
import { Helmet } from "react-helmet";
import { Col, Container, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import { fetchDocuments } from "../../store/actions";
import { logger } from "../../shared/Logger";

const CancelPrintingPage = (props) => {

    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const intl = useIntl();
    const dispatcher = useDispatch();

    useEffect(() => {
        if (id) {

            PrintingService.cancelOrder(id).then(response => {
                dispatcher(fetchDocuments());
            }).catch(error => {
                logger.error("Error while try to cancel printing order", JSON.stringify(error))
            }).finally(() => setLoading(false))

        }
    }, [id]);

    return (
        <PrivateTemplate>
            <Helmet>
                <title>Zerocopy - {intl.formatMessage({id: "xxx", defaultMessage: "Cancel printing order"})}</title>
            </Helmet>
            <Container fluid className="text-center">
                <Row className="mb-3">
                    <Col className="pl-0">
                        {!loading && <FormattedMessage id="xxx" defaultMessage="Your order was cancelled." /> }
                        {loading && <CustomSpinner messageKey="Cancelling your order..." /> }
                    </Col>
                </Row>
                {!loading && <Row>
                    <Col className="pl-0">
                        <Link to="/" className="btn btn-outline-secondary text-decoration-none mt-2">
                            <FormattedMessage id="xxx" defaultMessage="Back to your library" />
                        </Link>
                    </Col>
                </Row>}
            </Container>
        </PrivateTemplate>
    );

}

export default CancelPrintingPage;